import { useContext } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { useAppData } from 'hooks/useAppData';
import LanguageContext from 'Language/LanguageContext';

const SsoBanner = () => {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const { FREYA_URL, MARCO_V4_URL, VASCO_V2_URL } = useAppData();

    if (language !== 'fr') {
        return null;
    }

    return (
        <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: t('home.sso_banner', { FREYA_URL, MARCO_URL: MARCO_V4_URL, VASCO_V2_URL }) }}
            className="subtitle home-sso-banner"
        />
    );
};

export default SsoBanner;
